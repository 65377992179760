document.addEventListener('DOMContentLoaded', () => {

	// // Get all "navbar-burger" elements
	// const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
	//
	// // Check if there are any navbar burgers
	// if ($navbarBurgers.length > 0) {
	//
	// 	// Add a click event on each of them
	// 	$navbarBurgers.forEach( el => {
	// 		el.addEventListener('click', () => {
	//
	// 			// Get the target from the "data-target" attribute
	// 			const target = el.dataset.target;
	// 			const $target = document.getElementById(target);
	//
	// 			// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
	// 			el.classList.toggle('is-active');
	// 			$target.classList.toggle('is-active');
	// 			document.querySelector('.nav-mmenu').classList.toggle('is-active');
	// 			document.querySelector('body').classList.toggle('is-mmenu');
	// 		});
	// 	});
	//
	//
	// }

	// var ddItems = document.querySelectorAll('.has-dropdown');
	// if (ddItems.length > 0) {
	// 	ddItems.forEach(function (element, index) {
	// 		var item =  element.querySelector(".navbar-link");
	// 		var mmenu = document.createElement("span");
	// 		mmenu.className = "dd-arrow";
	// 		mmenu.innerHTML = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/></svg>';
	// 		item.appendChild(mmenu);
	// 	});
	// }

	// //menu button
	var el = document.querySelector('.navbar-burger');

	el.onclick = function() {
		document.querySelector('body').classList.toggle('mmenu-active');
	}


	"use strict";

	const body = document.body;
	const menu = body.querySelector(".tab-menu");
	const menuItems = menu.querySelectorAll(".menu__item");
	var activeItem = menu.querySelector(".active");

	function clickItem(item, index) {
		menu.style.removeProperty("--timeOut");

		if (activeItem === item) return;

		if (activeItem) {
			activeItem.classList.remove("active");
		}

		item.classList.add("active");
		activeItem = item;
	}

	menuItems.forEach((item, index) => {
		item.addEventListener("click", () => clickItem(item, index));
	});

	// Functions to open and close a modal
	function openModal($el) {
		$el.classList.add('is-active');
	}

	function closeModal($el) {
		$el.classList.remove('is-active');
	}

	function closeAllModals() {
		(document.querySelectorAll('.modal') || []).forEach(($modal) => {
			closeModal($modal);
		});
	}

	// Add a click event on buttons to open a specific modal
	(document.querySelectorAll('.js-modal-trigger') || []).forEach(($trigger) => {
		const modal = $trigger.dataset.target;
		const $target = document.getElementById(modal);

		$trigger.addEventListener('click', () => {
			openModal($target);
		});
	});

	// Add a click event on various child elements to close the parent modal
	(document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
		const $target = $close.closest('.modal');

		$close.addEventListener('click', () => {
			closeModal($target);
		});
	});

	// Add a keyboard event to close all modals
	document.addEventListener('keydown', (event) => {
		const e = event || window.event;

		if (e.keyCode === 27) { // Escape key
			closeAllModals();
		}
	});

});

jQuery($ => {
	$(document).ready(function(){
		$(document).on('click', '.navbar-link', function(ev){
			//ev.preventDefault();
			//$(this).parent('.navbar-item').hasClass('dd-active').removeClass('dd-active');
			$(this).closest('.has-dropdown').addClass('dd-active');
			$(this).closest('.has-dropdown').children('.navbar-dropdown').slideToggle();
		});


		// The speed of the scroll in milliseconds
		const speed = 1000;
		$('a[href*="#"]')
			.filter((i, a) => a.getAttribute('href').startsWith('#') || a.href.startsWith(`${location.href}#`))
			.unbind('click.smoothScroll')
			.bind('click.smoothScroll', event => {
				const targetId = event.currentTarget.getAttribute('href').split('#')[1];
				const targetElement = document.getElementById(targetId);

				if (targetElement) {
					event.preventDefault();
					//history.pushState(null, null, null);
					var windowWidth = jQuery( window ).width();
					var scrollHelp;
					if(windowWidth < 992) {
						scrollHelp = 30;
					} else {
						scrollHelp = 30;
					}
					$('html, body').animate({ scrollTop: $(targetElement).offset().top - scrollHelp }, speed);
					if(targetId === 'form') {
						$('input[id="input_9_2"]').focus();
					}
				}
			});


		//mobile menu
		$("#mainNav").appendTo($(".more-button"));
	});
});
